import {commonRequestV1} from "./api/common";
import {useEffect, useState} from "react";


export const isLoggedInUser = () =>{
    return false
}

export const ProtectedRoute = ({children}) =>{

    const [loading, setLoading] = useState(true);

    useEffect(() =>{
        let g = async () =>{
            try{
                await commonRequestV1("GET", "/protectedRoute");
                setLoading(false)
            }catch (e){
                console.log(e)
                let status_code = e?.response?.status;
                if (status_code === 401){
                    return window.location.assign("/user/login")
                }// Token not found
                setLoading(false)
            }
        }

        g();
    }, [])

    return loading? undefined: children;

}