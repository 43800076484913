// Anything exported from this file is importable by other in-browser modules.
// import ComingSoon from "./global-components/comingSoon";
import "./global.css?modules=false";
import { BASE_URL } from "./user/api/common";
export { ProtectedRoute } from "./user/common";
export { ComingSoon } from './global-components/comingSoon';
export { Explore } from './global-components/explore';
export { DashboardPage } from './global-components/dashboardPage'
export { Resource } from "./user/api/resource";

export function publicApiFunction() { }

export { isLoggedInUser } from "./user/common";
