import React from 'react'
import './global-components.css';

export const ComingSoon = () => {
    return (
        <div>
            <div className='comingsoonWrapper'>
                <div className='comingsoonmain'>
                    <img src='https://idflow-workflow-images.s3.ap-south-1.amazonaws.com/rock-unscreen.gif' />
                    <h3>Coming Soon..</h3>
                    <p> Get ready! an exciting new feature is almost here.</p>
                    <button onClick={() => window.history.go(-1)}>Go Back</button>
                </div>
            </div>
        </div>
    )
}

